.form-auto1{
    width: 55%;
    background: white;
    margin: 0 auto;
    padding: 55px 55px;
}

.form-auto2{
    width: 75%;
    background: white;
    margin: 0 auto;
    padding: 64px 81px;
}

.h2-file-itr h2{
    font-size: 28px;
    margin-bottom: 22px;
    color: black;
    margin-left: 12px;
}
.round-borders p:nth-child(1){
    width: 30px;
    height: 30px;
    background-color: white;
    border: 3px solid #000000a3;;
    border-radius: 50%;
    margin-bottom: 0;
}
.round-borders p:nth-child(2){
    color: #000000a3;
    font-size: 26px;
    margin-bottom: 0;
    margin: 0 9px;
}
.steps-file-itr{
    display: flex;
    justify-content: center;
    align-items: center;


}
.round-borders{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 57px;
}
.content-file-itr h2{
    color: black;
}
.content-file-itr p{
    color: black;
    margin-bottom: 0;
    
}
.content-file-itr h2{
    font-weight: 600;
    font-size: 18px;
    color: black;
}
.main-steps-files{
    display: flex;
    height: 170px;
    flex-direction: column;
}
.start-fil-butn{
    background: #000000 !important;
    padding: 20px;
    border-radius: 15px 0px;
    text-decoration: none;
    margin: auto;
    /* border: 4px solid #474747bd; */
    margin-top: 50px;
    width: 100%;
    padding: 0 !important;
    text-align: center;
    color: white;
}

.verify-your-detail h2{
    font-size: 18px;
    margin-top: 0;
    color: black;
    font-weight: 600;
}
.main-grid-form{
    display: grid;
    width: 100%;
    grid-gap: 20px;
    grid-template-columns: repeat(2,1fr);
}
.main-grid-form .form-floating input{
    padding: 0 23px !important;
}

.start-fil-butn {
    background: #000000 !important;
    padding: 20px;
    border-radius: 15px 0px;
    text-decoration: none;
    margin: auto;
    /* border: 4px solid #474747bd; */
    margin-top: 50px;
    width: 100%;
    padding: 0 !important;
    text-align: center;
    color: white;
}
.start-fil-butn2 {
    background: #000000 !important;
    padding: 20px;
    border-radius: 15px 0px;
    text-decoration: none;
    margin: auto;

    width: 100%;
    padding: 0 !important;
    text-align: center;
    color: white;
}
.main-grid-form .form-floating select {
    padding: 0 23px !important;
}

.form-upload h2{
    color: #000000;
}
.form-upload p{
    color: #000000;
}
.form-upload a{
    color: #000000;
}
.address-file{
    padding: 18px 23px;
}
.form-auto3 {
    width: 55%;
    background: white;
    margin: 0 auto;
    padding: 64px 46px;
}
.form-login-main{
    margin: 140px 0 100px 0;
}
.plan-shadow-border {
    border: 2px solid #e1c9c9;
    padding: 80px 60px;
    border-radius: 20px;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
}
.input-login1 input{
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-bottom: 42px;
}
.input-login2 input{
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-bottom: 14px;
}
.start-fil-login-btn {
    background: white !important;
    padding: 13px 20px;
    border-radius: 15px 0px;
    text-decoration: none;
    margin: auto;
    display: inline-block;
    width: 100%;
    margin: 0px !important;
    /* padding: 0 !important; */
    text-align: center;
    color: black !important;
    height: auto;
    letter-spacing: 1px;
    font-weight: 900;
    margin-top: 20px;
}

.header-login h2{
    text-align: center;
    font-size: 24px;
    letter-spacing: 8px;
    margin-bottom: 30px;
}
.plan-shadow-border{
    padding: 80px 60px;
    width: 50%;
    margin: 0 auto;
    background: black;

}
.create-accounts{
    text-align: center;
    margin-top: 16px;
}
.form-control:focus{
    color: #fff !important;
    background-color: transparent !important;
    border-color: #fff;
    outline: 0;
    box-shadow: none !important;
}
.input-login-button .login-btn{
    font-weight: 800 !important;
}
.input-login-button{
    margin-top: 20px !important;
}
.section-term-and-conditions{
    margin:140px 114px 100px 114px;
}
.term-and-condition-main h2{
    text-align: center;
}
.term-condition-div{
    margin-top: 57px;
}
.term-condition-div ul li{
    list-style-type: decimal;
    margin-bottom: 5px;
}
.term-condition-div h4{
   background: black;
    padding: 20px 20px;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid #ffffff4f;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 0 8px 16px 0 rgb(107 107 107 / 20%);
    margin-top: 100px;
}
.intoductions h3{
  background: black;
    padding: 20px 20px;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid #ffffff4f;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 0 8px 16px 0 rgb(107 107 107 / 20%);
    margin-top: 0;
}
.term-condition-div  h6,
.term-condition-div  p{
    padding: 0 30px;
    text-align: justify;
}
.main-user-forms{
    margin: 140px 0;

}