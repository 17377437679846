.plan-table .u-table-entity .u-section-2 .u-btn {
  border-style: none;
  background-image: none;
  font-size: 0.875rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;

  background-color: #ec3d45 !important;
}

.plan-table thead tr th:first-child {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 22px;
  background: #ffffff;
  color: #111;
}

.plan-table thead tr th:nth-child(2) {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 22px;
  background: #ff9d00;
  color: #fff;
}

.plan-table thead tr th:nth-child(3) {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 22px;
  background: #ec3d45;
  color: #fff;
}

.plan-table thead tr th:nth-child(4) {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 22px;
  background: #08b9ca;
  color: #fff;
}

.plan-table tbody tr td {
  border: 1px solid #ffffff;
  padding: 20px 10px;
  font-size: 15px;
}

.dsn-btn .title-btn {
  position: relative;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 2px;
  font-weight: 700;
}

.v-light .socials li a i.fa-linkedin-in {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: #0077b5 !important;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  height: 40px;
}

.v-light .socials li a i.fa-facebook-f {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: #3b5998 !important;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  height: 40px;
}

.v-light .socials li a i.fa-twitter {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: #55acee !important;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  height: 40px;
}

.v-light .socials li a i.fa-instagram {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* background: #d6249f !important; */
  background: radial-gradient(circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%) !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  height: 40px;
}

.v-light .main-root .dsn-socials li:nth-child(1) a {
  color: white !important;
  background: #3b5998 !important;
  width: 40px;
  height: 40px;
}

.v-light .main-root .dsn-socials li:nth-child(2) a {
  color: white !important;
  background: #55acee !important;
  width: 40px;
  height: 40px;
}

.v-light .main-root .dsn-socials li:nth-child(3) a {
  color: white !important;
  background: #0077b5 !important;
  width: 40px;
  height: 40px;
}

.v-light .main-root .dsn-socials li:not(:first-child) {
  margin-left: 10px;
}

.v-light .main-root .dsn-socials li:nth-child(4) a {
  color: white !important;
  background: radial-gradient(circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%) !important;

  width: 40px;
  height: 40px;
}

.v-light .main-root .dsn-socials li a .dsn-icon i {
  color: white;
}

.btn-table td a {
  background-color: #ec3d45;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  padding: 10px 20px;
  color: #fff;
  border-radius: 10px;
}

.v-light .main-root .site-header {
  background-color: white !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-light .main-root #dsn_header #hero_image {
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.v-light .main-root #dsn_header:not(.header-normal):before {
  background-color: transparent;
}

.v-light {
  background-color: white !important;
}

.v-light .main-root #hero_title .title strong {
  color: #0d4b9a;
}

.v-light .main-root .subHeader-animation {
  color: #111;
}

.v-light .main-root .background-section .dsn-bg-before:before {
  background-color: #fa7b04;
}

.v-light .main-root .intro-title .subtitle-meta {
  color: #111;
  font-weight: 500;
}

.v-light .main-root .dsn-service .service_title {
  text-transform: uppercase;
  font-weight: 700;
}

.v-light .main-root footer {
  background: none !important;
}

.v-light .main-root .title-h2 {
  font-size: 40px;
  letter-spacing: 2px;
  font-weight: 600;
}

.v-light .main-root .dsn-icon svg,
.v-light .main-root .dsn-icon svg path {
  fill: #ed9242;
}

.v-light .main-root .service-item-inner {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(3, 0, 71, 0.09);
  overflow: hidden;
  border-radius: 8px;
  background: #ffffff;
  border-radius: 10px;

  box-shadow: 0px 1px 3px rgba(3, 0, 71, 0.09);
}

.v-light .main-root .parents-margin .section-margin {
  margin-top: 0px !important;
  margin-bottom: 80px !important;
}

.v-light .main-root .parent-service-section .service-section {
  padding: 50px 0px;
  background: none;
}

body.v-light {
  background: none !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
button {
  font-family: "Montserrat", sans-serif !important;
}

.step-container-list {
  position: relative;
  text-align: center;
  transform: translateY(-43%);
}

.step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #007bff;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  /* Added cursor pointer */
}

.step-line {
  position: absolute;
  top: 16px;
  left: 50px;
  width: calc(100% - 100px);
  height: 2px;
  background-color: #007bff;
  z-index: -1;
}

/* #multi-step-form{
overflow-x: hidden;
} */
.form-height {
  min-height: 70vh;
  margin-top: 150px !important;
  padding-bottom: 50px;
}

.form-next-btn button {
  background-color: var(--assistant-color);
  color: white !important;
}

.input__wrap .entry-box input,
.input__wrap .entry-box select,
.input__wrap .entry-box option {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  border: 1px solid var(--border-color);
  padding: 10px 18px;
  color: var(--heading-color);
  margin-bottom: 0;
  background-color: transparent;
}

.return-heads {
  width: 100%;
  border: 1px solid var(--border-color);
  padding: 10px 18px;
  color: var(--heading-color);
  margin-bottom: 0;
  background-color: transparent;
}

.grid-capacity {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

.form-height .form-card {
  width: 90%;
  margin-top: 100px;
}

.return-heads h3 {
  font-size: 14px;
}

.return-heads h5 {
  font-size: 13px;
  font-weight: 300;
  margin: 10px 0px;
}

.v-light .assets-input input {
  height: 40px;
  position: relative;
  z-index: 9999;
  background: transparent;
  width: 100%;
  border: none;
  color: #111 !important;
}

.v-light .assets-input input {
  border-bottom: 1px solid #111 dotted !important;

}

.return-heads p {
  font-size: 12px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

.return-heads p i {
  font-size: 16px;
  font-weight: 900;
  color: #111;
}

.step .input__wrap {
  margin-top: 30px;
}

.entry-box label {
  font-weight: 600;
  margin-bottom: 10px !important;
  display: inline-block;
}

.margin-con {
  margin-bottom: 30px !important;
}

.form-card {
  width: 70%;
  margin: auto;
  display: block;
}

.steps-all-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.steps-all-btn button {
  position: relative;
  font-size: 13px;
  line-height: 16px;

  letter-spacing: 3px;
  border: 1px solid #ffffff1a;
  text-transform: uppercase;
  color: var(--heading-color);
  padding: 20px 30px;
  cursor: pointer;
  z-index: 9;
  min-width: 150px;
  margin-top: 20px;
}

.service-lists-all {
  display: flex !important;
  flex-direction: column !important;
}

.service-section .left h5.title-h2 b {
  font-size: 50px !important;
}

.parent-service-section .service-section {
  padding: 50px 0px;
}

.return-radio p {
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid;
  border-radius: 50%;
}

.return-radio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.return-card.add-radio .return-radio p {
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid;
  border-radius: 50%;
}

.assets-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
}

.assets-card h2 {
  font-size: 14px;
  margin-bottom: 30px;
  text-align: center;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.assets-card {
  border: 1px solid #645b5b;
  padding: 30px;
  border-radius: 20px;
}

.assets-input input {
  height: 40px;
  position: relative;
  z-index: 9999;
  background: transparent;
  width: 100%;
  border: none;
  color: #fff !important;
  text-align: center;
  font-size: 15px;
  border-bottom: 1px solid #fff dotted;
  border-bottom: 2px dashed #d9cccc;
}

.v-light .footer-btns-corner a {
  color: white !important;
}

.v-light .assets-input input {
  border-bottom: 1px solid #111 dotted;
  border-bottom: 2px dashed #111;
}

.assets-input input::placeholder {
  color: #fff !important;
  opacity: 1;
  /* Firefox */
}

a {
  text-decoration: none;
  color: inherit !important;
}

.assets-input input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #fff !important;
}

.steps-all-btn .icon-circle {
  display: none !important;
}

.steps-all-btn button:hover {
  color: inherit !important;

}

.form-card .progress {
  display: none !important;
}

.register-btns {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.register-inform-modal .modal-body p {
  color: #111;
}

.register-btns a {
  color: green !important;
  font-weight: 800;
  text-decoration: none;
}

.menu-footer-menu-container-list .menu-item a {
  text-decoration: none;
}

.footer-btns-corner {
  position: fixed;
  right: 20px;
  background: black;
  padding: 20px;
  bottom: 30px;
  border-radius: 15px 0px;
  z-index: 999999;
}

.footer-btns-corner a {
  color: white;
  font-weight: bold;
  text-decoration: none
}

@media screen and (max-width: 767px) {
  .about-section .box-creative {
    padding: 0 0 30px 15px;
  }

  .subHeader-animation {
    white-space: initial;
    overflow: hidden;
    width: 100%;
  }

  .about-section .box-creative .img-top, .about-section .box-creative .img-bottom {
    position: relative;
    min-width: 190px;
    display: none;
  }

  .about-section .box-creative .img-top {
    width: 100%;
    top: 30px;
    left: 0;
  }

  .assets-grid-3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }

  .assets-input input {
    width: 100%;
  }

  .return-radio~p {
    justify-content: flex-start;
  }

  .steps-all-btn button {
    position: relative;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 3px;
    border: 1px solid #ffffff1a;
    text-transform: uppercase;
    color: var(--heading-color);
    padding: 15px 15px;
    cursor: pointer;
    z-index: 9;
    min-width: 150px;
    margin-top: 20px;
  }

  .grid-capacity {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .assets-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }

  html,
  body {
    overflow-x: hidden;
  }

  .main-navigation ul li a span {
    font-size: 15px;
  }

  .intro-title .subtitle-meta {
    width: 100% !important;
  }

  .dsn-service .service_title {
    text-transform: uppercase;
    font-size: 13px;
  }

  .service_description p {
    font-size: 12px;
  }

  .plan-table .u-table-entity .u-section-2 .u-btn {
    border-style: none;
    background-image: none;
    font-size: 0.875rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;

    background-color: #ec3d45 !important;
  }

  .plan-table thead tr th:first-child {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 13px;
    background: #ffffff;
    color: #111;
  }

  .plan-table thead tr th:nth-child(2) {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 13px;
    background: #ff9d00;
    color: #fff;
  }

  .plan-table thead tr th:nth-child(3) {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 13px;
    background: #ec3d45;
    color: #fff;
  }

  .plan-table thead tr th:nth-child(4) {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 13px;
    background: #08b9ca;
    color: #fff;
  }

  .plan-table tbody tr td {
    border: 1px solid #ffffff;
    padding: 10px 5px;
    font-size: 12px;
  }

  .btn-table td a {
    background-color: #ec3d45;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    padding: 10px 20px;
    color: #fff;
    border-radius: 10px;
  }

  .title-h2 {
    font-size: 19px;
    line-height: inherit;
  }

  #hero_title .font-sizing {
    font-size: 12px !important;
  }

  .list-style1 ul li {
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding-left: 10px;
  }

  .contain-w {
    width: 96%;
    margin-right: auto;
    margin-left: auto;
  }

  #hero_title h1 {
    font-size: 20px !important;
    line-height: 34px !important;
  }

  body .social-side {
    display: none !important;
  }

  .desktop-menu-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #131313;
    padding: 10px 15px;
  }
}
.bg-change-one{
  background: #302d2d !important;
}
.second-bg{
  background-color: #221f1f;
}
.plan-height{
  min-height: 400px;
}
.space-top{
  margin-top: 15px;
}
.plan-height .service-content h6{
  margin-bottom: 20px;
  font-size: 16px !important;
}
.bg-gold{
  background: linear-gradient(93.31deg, rgb(140, 97, 21) -22.19%, rgb(151, 109, 35) -17.01%, rgb(181, 142, 72) -6.02%, rgb(229, 195, 132) 8.85%, rgb(233, 199, 137) 10.15%, rgb(255, 234, 196) 25.67%, rgb(230, 185, 102) 68.35%, rgb(153, 111, 37) 107.15%) !important; 
}
.plan-shadow{
  width: 75% !important;
}
.plan-shadow li:last-child{
  border:none !important;
}
.lists-all span{
  font-size: 20px;
  margin-left: 10px;
}
.itr-lists{
  margin-top: 25px;
}
.lists-all{
  margin-bottom: 20px !important;
  font-size: 16px;
}

.container-list {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 35px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-list input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-list:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-list input:checked ~ .checkmark {
  background-color: grey !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-list input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-list .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}