.headerFont {
  font-size: 66px !important;
  line-height: 0px !important;
}
strong > b {
  margin-right: 20px;
}

.hero-padding {
  padding-top: 30px !important;
  padding-bottom: 40px !important;
}
.list-style1 i {
  font-size: 8px;
  margin-right: 4px;
}
.list-style1 ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px !important;
}
.list-style1 {
  display: flex;
  justify-content: center;
}
#hero_title .font-sizing {
  font-size: 31px !important;
}
.font-sizing {
  display: flex;
  justify-content: center;
}
.contain-w {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
}
.contain-w .text-size {
  font-size: 18px;
}
.contain-w .text-s {
  font-size: 14px;
}
.intro-title h1 del {
  text-decoration: line-through;
  padding: 0px 10px;
}
.background-theme p {
  margin-top: 15px;
  font-size: 13px;
}
.body-font b {
  font-size: 20px;
}

.align-self-end {
  align-self: flex-start !important;
}

.subHeader-animation {
  /* color:pink;
  font-family: "Courier"; */
  /* font-size: 20px;
  margin: 10px 0 0 10px;*/
  white-space: nowrap;
  overflow: hidden;
  width: 30em;
  animation: example 3s steps(60, start);
}
.subHeader-animation:nth-child(2) {
  animation: example 8s steps(60, end);
  font-size: 30px;
}
.subHeader-animation:nth-child(2) span {
  text-transform: uppercase;
  font-weight: bold;
}
@keyframes example {
  from {
    width: 0;
  }
}

@keyframes example {
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  100% {
    width: 100;
  }
}
.grid-row-gap-10 {
  grid-row-gap: 10px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  border: transparent;
  border-bottom: 2px dotted #fff;
  background: transparent;
  color: #fff;
}
.btn-space {
  display: flex;
  justify-content: space-between;
}
.sample-grid {
  display: grid;

  grid-template-rows: auto;
  row-gap: 30px;
}
.assets-form {
  margin-top: 50px;
}
.goalbox-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.goalbox-content > p {
  color: var(--heading-color) !important;
}
.assets-grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
}
.text-center {
  text-align: center;
}

.bg {
  display: block;
  background: red;
  width: 100%;
  height: 100%;
}

/* sj */
.phone {
  display: block;
}
button {
  display: none;
}

@media screen and (max-width: 767px) {
  button {
    display: block;
    position: relative;
    left: 10px;
    top: 12 px;
    color: #fff;
  }
  .phone {
    display: none !important;
    position: absolute;
    top: 40px;
    left: -50px;
  }
  .navbar {
    display: block;
  }
  .logo {
    left: 20px;
  }
  .main-navigation-menu .menu ul {
    display: block !important;
    /* background-color: white;
    color: #000;
    position: absolute;
    left: 50px;
    width: 375px;
    top: 30px;
    z-index: 11; */
  }
  .openMobilMenu {
    display: block !important;
    position: absolute;
    top: 0px;
    padding-top: 140px;
    height: 100vh;
    background: #454545;
    color: #fff;
    width: 100%;
    transition: all 0.5s ease-in-out;
    animation: openMenu 0.4s ease-out forwards;
    animation: showMenu 0.4s ease-out forwards;
  }

  @keyframes openMenu {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes showMenu {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }

  .openMobilMenu .menu {
    padding: 10px;
  }
  .openMobilMenu .menu ul li {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0px;
  }
}
/* eligible button  */

.eligible {
  border: 1px solid rgb(3, 3, 3);
  background: #e4e4dd;
  border-radius: 22px 0px 22px 0px;
  position: fixed;
  color: black;
  bottom: 20px;
  right: 30px;
  z-index: 111;
  text-align: center;
  padding: 8px;
}

.eligi-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 155px;
  height: 45px;
  left: 80px;
  top: 18px;
  background: #4c4646;
  border-radius: 22px 0px 22px 0px;
  animation: pulse-border 1500ms ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.footer-connect-headings p {
  font-size: 21px;
  width: 82%;
  margin: 25px auto;
}
.dsn-service .service_title {
  font-size: 20px;
  margin: 15px 0px;
}
.whychoose-paragraph p {
  font-size: 16px !important;
}
.service-item-inner .dsn-icon i {
  font-size: 40px;
}
.list-item i {
  font-size: 15px !important;
}
.text-calls {
  margin-top: 90px;
}
.whyimg img {
  margin-top: 20px;
  border-radius: 40px;
}
.social-icons {
  width: 30px;
  text-align: center;
  height: 30px;
  border-radius: 50px;
  background: white;
}
.social-icons .dsn-icon i {
  color: #111;
  line-height: 30px;
  font-size: 15px;
}
body,
html {
  overflow-x: hidden;
}
.num-font .award-item h5 {
  font-size: 30px !important;
}
.num-font .award-item h4 {
  font-size: 15px !important;
}
.award-item i {
  font-size: 40px;
  margin-bottom: 30px;
}
.bookappointment {
  position: relative;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(--heading-color);
  padding: 20px 30px;
  cursor: pointer;
  z-index: 9;
  min-width: 150px;
}
.bookappointment a {
  border: 1px solid #afa5a5;
  padding: 15px 28px;
  margin-top: 30px;
  border-radius: 6px;
}
.plan-shadow {
  border: 2px solid #e1c9c9;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
}
.plan-shadow h5 {
  background: white;
  text-align: center;
  color: black;
  padding: 10px;
  border-radius: 0px 40px 40px 0px;
}
.inner-list li {
  list-style-type: circle;
  margin: 20px 0px;
}
.inner-list {
  list-style-position: inside;
  min-height: 420px;
}

ul.intro-project-list > li {
  margin-bottom: 0;
  border-right: 1px solid #ffffff82;
  padding-right: 10px;
}
.blog-item-inner .left-box {
  flex: 0 1 100% !important;
  margin-bottom: 30px;
}
.left-box .font-caps {
  line-height: 30px;
  font-size: 14px !important;
}
.root-blog .blog-item {
  padding-left: 60px;
  padding-top: var(--blog-g, 20px);
  padding-bottom: var(--blog-g, 0px);
}
.summart-text p {
  font-size: 21px !important;
  line-height: 35px;
}
.margin-decrease-margin {
  margin-top: -3px !important;
}
.plan-main-card .list-style1 ul li {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 15px !important;
}
.plan-main-card {
  flex-direction: column;
  flex-wrap: wrap;
}
#hero_title h5 del {
  text-decoration: line-through;
}
.itr-borders {
  border: 1px solid #d0cbcb;
}
.itr-borders .service-content .service_title {
  letter-spacing: 2px;
  font-size: 20px;
  line-height: 20px;
  margin-top: 35px;
  /* min-height: 48px !important; */
  font-weight: 700;
}
.offers p span del {
  font-size: 18px;
  text-decoration: line-through;
  margin-right: 15px;
  margin-left: 10px;
}
.offers p:first-child {
  background: white;
  width: 65%;
  height: auto;
  padding: 6px;
  /* line-height: 50px; */
  margin: auto;
  font-weight: 800;
  color: black;
  text-align: center;
  /* clip-path: polygon( 50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20% ); */
  /* clip-path: ellipse(50% 33% at 50% 50%); */
  /* clip-path: polygon( 50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12% ); */
  border-radius: 45px 0px;
}
.offers p:last-child {
  margin-top: 25px;
  font-size: 30px;
}
.slider-grid .service-item {
  padding: 0px 10px !important;
  border-radius: 20px 0px !important;
}
.form-steppers {
  margin-top: 200px;
}
.slider-grid .swiper-prev {
  position: absolute;
  top: 50%;
  left: -50px;
  z-index: 999;
}
.slider-grid .swiper-next {
  position: absolute;
  top: 50%;
  right: -50px;
  z-index: 999;
}
.user-input-form {
  border: 2px solid #e6dede70;
  margin: 15px;
  padding: 25px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-input-form {
  border: 2px solid #e6dede70;
  margin: 15px;
  padding: 25px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-input-form h5 {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}
.user-input-form .form-group .entry-box input {
  width: 100%;
  border-radius: 10px;
}
.entry-coupon {
  margin-top: 45px;
}
.apply-coupan p del {
  text-decoration: line-through;
  font-weight: 800;
  font-size: 25px;
  font-weight: 800;
}
.apply-coupan span {
  font-size: 32px;
  margin: 0px 26px;
}
.apply-coupan p span:nth-child(3) {
  background: white;
  color: #111;
  font-size: 22px;
  padding: 10px 20px;
  font-weight: 800;
  border-radius: 20px 0px;
}
.apply-coupan p {
  text-align: center;
}
.apply-coupon-box {
  display: flex;
  flex-wrap: wrap;
}
.apply-coupon-box input {
  width: 70% !important;
}
.apply-coupon-box a {
  width: 25% !important;
  background: white;
  color: #111 !important;
  text-align: center;
  display: flex;
  margin-left: 5%;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 10px;
}
.decs-padding {
  padding: 30px;
}

.slider-grid .service-item-inner{
  padding-bottom: 0px !important;
  padding-top: 20px !important;
}
.slider-grid .service-item-inner .bookappointment{
  position: relative;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: var(--heading-color);
    padding: 20px 0px;
    cursor: pointer;
    z-index: 9;
    min-width: 150px;
}
.slider-grid .service-item-inner .bookappointment a{
  margin-top: 0px;
}
a:hover{
  text-decoration: none !important;
}
.calendly-inline-widget  iframe  body{
  overflow-y: hidden !important ;
}
.calendly-inline-widget body{
  overflow: hidden !important;
}
.calendly-inline-widget iframe body{
  overflow: hidden !important;
}
._2eZHnh_PMhyzt7w_zi7 {
  overflow: hidden;
}
.openMobilMenu .menu {
  display: block!important;
} 
.v-light .desktop-menu-header{
  background-color: white;
}

.v-light .main-root .site-header {
  background-color: white !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-light .main-root #dsn_header #hero_image {
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.v-light .main-root #dsn_header:not(.header-normal):before {
  background-color: transparent;
}

.v-light {
  background-color: white !important;
}

.v-light   #hero_title .title strong {
  color: #0d4b9a;
}

.v-light   .subHeader-animation {
  color: #111;
}

.v-light   .background-section .dsn-bg-before:before {
  background-color: #fa7b04;
}

.v-light   .intro-title .subtitle-meta {
  color: #111;
  font-weight: 500;
}

.v-light   .dsn-service .service_title {
  text-transform: uppercase;
  font-weight: 700;
}

.v-light  footer {
  background: none !important;
}

.v-light  .title-h2 {
  font-size: 40px;
  letter-spacing: 2px;
  font-weight: 600;
}

.v-light  .dsn-icon svg,
.v-light  .dsn-icon svg path {
  fill: #ed9242;
}

.v-light  .service-item-inner {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(3, 0, 71, 0.09);
  overflow: hidden;
  border-radius: 8px;
  background: #ffffff;
  border-radius: 10px;

  box-shadow: 0px 1px 3px rgba(3, 0, 71, 0.09);
}

.v-light  .parents-margin .section-margin {
  margin-top: 0px !important;
  margin-bottom: 80px !important;
}

.v-light .parent-service-section .service-section {
  padding: 50px 0px;
  background: none;
}

body.v-light {
  background: none !important;
}
.v-light  #dsn_header:not(.header-normal):before {
  background-color: transparent;
} 
.v-light  #dsn_header #hero_image {
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.v-light #dsn_header {
  position: relative;
  overflow: hidden;
  --margin-padding: 80px;
  background-color: white;
}

.v-light  .background-section .dsn-bg-before:before {
  background-color: #fa7b04 !important;
}
.v-light{
  font-weight: 600 !important;
}
.v-light .socials li a i.fa-linkedin-in {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: #0077b5 !important;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  height: 40px;
}

.v-light .socials li a i.fa-facebook-f {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: #3b5998 !important;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  height: 40px;
}

.v-light .socials li a i.fa-twitter {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: #55acee !important;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  height: 40px;
}

.v-light .socials li a i.fa-instagram {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* background: #d6249f !important; */
  background: radial-gradient(circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%) !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  height: 40px;
}

.v-light .dsn-socials li:nth-child(1) a {
  color: white !important;
  background: #3b5998 !important;
  width: 40px;
  height: 40px;
}

.v-light .dsn-socials li:nth-child(2) a {
  color: white !important;
  background: #55acee !important;
  width: 40px;
  height: 40px;
}

.v-light .dsn-socials li:nth-child(3) a {
  color: white !important;
  background: #0077b5 !important;
  width: 40px;
  height: 40px;
}

.v-light .dsn-socials li:not(:first-child) {
  margin-left: 10px;
}

.v-light .dsn-socials li:nth-child(4) a {
  color: white !important;
  background: radial-gradient(circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%) !important;

  width: 40px;
  height: 40px;
}

.v-light .dsn-socials li a .dsn-icon i {
  color: white;
}
.v-light .service-section {
  margin-bottom: 70px !important;
}
.steps-all-btn input{
  position: relative;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 3px;
  border: 1px solid #ffffff1a;
  text-transform: uppercase;
  color: var(--heading-color);
  padding: 20px 30px;
  cursor: pointer;
  z-index: 9;
  min-width: 150px;
  margin-top: 20px;
}

.steps-all-btn input:hover{
  color: inherit !important;

}