
.desktop-menu-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #131313;
  }
  .main-navigation-menu .menu ul{
    display: flex;
  }
  .main-navigation-menu .menu ul li a{
    text-transform: capitalize;
    padding: 15px;
    letter-spacing: 1px;
    font-weight: 600;
  }
  .About-center-img{
    height: 400px !important;
  }
  .footer-connect-headings{
    margin-top: 200px ;
  }
  .testimonial-slider-card .slick-slider-prev{
    position: absolute;
    bottom: -220px;
    left: 0%;
  }
  .testimonial-slider-card .slick-slider-next{
    position: absolute;
    bottom: -220px;
    left: 20%;
  }